import React from "react";
import "./styles.scss";

const Header = () => (
  <div className="container">
    <header className="header">
      <div className="header__logo">
        <a href="https://www.medicalpay.app/">
          <img src="https://medicalpay.app/logohorizontal.png" width={400} />
        </a>
      </div>
    </header>
  </div>
);

export default Header;
