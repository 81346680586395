import { api, apiVersion } from "./axiosConfig";

export const getHash = async (params) => {
  const route = `/api/v${apiVersion}/checkin/get-hash`;
  const _params = {
    hash: params,
  };
  return await api
    .post(route, _params)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data,
        };
      } else {
        return {
          success: false,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
      };
    });
};

export const setHash = async (hash = "") => {
  const UID = "10874";

  const route = `/api/v${apiVersion}/checkin/set-hash-unidade?unidadeid=${UID}`;

  return await api
    .post(route, { hash })
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data,
        };
      } else {
        return {
          success: false,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
      };
    });
};

export const _buscarCheckinProfissional = async (
  hash,
  pagina,
  numeroDeregistros
) => {
  const route = `/api/v${apiVersion}/plantao/obter-profissionais-checkinQrCode?hash=${hash}&pagina=${pagina}&numeroDeregistros=${numeroDeregistros}`;

  return await api
    .get(route)
    .then((res) => {
      if (res.status === 200) {
        return {
          success: true,
          data: res.data,
        };
      } else {
        return {
          success: false,
          message: "Ocorreu um erro",
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        message: error,
      };
    });
};
