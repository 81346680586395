import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function LinearDeterminate({ progress }) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    display: progress === 0 ? "none" : "inherit",
    marginTop: 10,
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      borderRadius: 5,
      background: "#1E9600" /* fallback for old browsers */,
      background:
        "-webkit-linear-gradient(to left, #FF0000, #FFF200, #1E9600)" /* Chrome 10-25, Safari 5.1-6 */,
      background:
        "linear-gradient(to left, #FF0000, #FFF200, #1E9600)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
  }));

  return (
    <Box sx={{ width: "100%", height: 10 }} key="progress-bar">
      <BorderLinearProgress variant="determinate" value={progress} />
    </Box>
  );
}
