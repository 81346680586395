import React from "react";

import Home from "./pages/Home/index.js";

import "./App.js";

function App() {
  return <Home />;
}

export default App;
