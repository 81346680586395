import axios from "axios";
import ambiente from "./ambienteConfig";

const apiVersion = ambiente.apiVersion;

const api = axios.create({
  baseURL: ambiente.AMBProducao,
});

api.interceptors.request.use((config) => {
  let token = sessionStorage.getItem("token");
  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    ContentType: "application/json; charset=utf-8",
  };

  return config;
});

export { api, apiVersion };
