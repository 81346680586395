import React, { useState, useEffect } from "react";
import QrCode from "qrcode.react";

import "./styles.scss";
import LinearDeterminate from "./ProgressBar";
import "moment/locale/pt-br";
import moment from "moment";
import TutorialImages from "./TutorialImages";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import selectDay from "../../../assets/selecionar-dia.jpeg";
import selectCheckin from "../../../assets/selecionar-checkin.jpeg";
import confirmMessage from "../../../assets/escanear-qrcode.jpeg";
import processDone from "../../../assets/finalizado.jpeg";
import { Link } from "@mui/material";
import EquipesValidadas from "./EquipesValidadas";
import {
  getHash,
  setHash,
  _buscarCheckinProfissional,
} from "../../../services/index";
import Modal from "./ModalEquipes";

const images = [
  {
    image: selectDay,
    alt: "1 - Abra o app Medical App e selecione o dia do plantão 📱",
  },
  {
    image: selectCheckin,
    alt: "2 - Selecione a opção chekin ou checkout 🎯",
  },
  {
    image: confirmMessage,
    alt: "3 - Escanear o QR code ao lado e confirme a mensagem ✅",
  },
];

const images_1 = [
  {
    image: selectDay,
    alt: "1 - Abra o app Medical App e selecione o clique no leitor de QR code 📱",
  },
  {
    image: selectCheckin,
    alt: "2- Aponte a câmera do seu celular para o QR code data tela 📷",
  },
  {
    image: confirmMessage,
    alt: "3 - Pronto, QR code validado com sucesso ✅",
  },
];

const TutorialGuide = () => {
  const [qrCodeValue, setqrCodeValue] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [progress, setProgress] = useState(0);

  const [open, setOpen] = useState(false);

  const [token, setToken] = useState(null);

  const [firstCall, setFirstCall] = useState(true);

  const [profissionalCheckin, setProfissionalCheckin] = useState({
    registros: [],
    totalDeRegistros: 0,
  });

  const [loading, setLoading] = useState(false);

  const buscarHash = async (_token = false) => {
    const res = await getHash(_token);
    if (res.success) {
      setToken(res.data.jwt);
      setqrCodeValue(`medicalapp_${res.data.jwt}`);
    }
  };

  const buscarCheckinProfissional = async (
    numeroDeregistros = 10,
    pagina = 1
  ) => {
    setLoading(true);
    const token = localStorage.getItem("token_QRCode");
    const res = await _buscarCheckinProfissional(
      token,
      pagina,
      numeroDeregistros
    );

    if (res.success) {
      if (!open) setOpen(!open);
      setProfissionalCheckin({
        registros: res.data.map((i) => {
          return {
            Nome: i.Nome,
            Checkin: moment(i.Checkin, "YYYY-MM-DD HH:mm").format(
              "DD/MM/YYYY - HH:mm"
            ),
          };
        }),
        totalDeRegistros:
          res.data.length > 0 ? res.data[0].totalDeRegistros : 0,
      });
    }
    setLoading(false);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const validarHash = async () => {
    const res = await setHash();
    let res1 = { success: true, data: { jwt: "" } };

    if (res.success) {
      res1 = await setHash(res.data.jwt);
    }

    if (res1.success && res1.data.jwt) {
      await buscarHash(res1.data.jwt);
      setFirstCall(false);
    }
  };

  useEffect(() => {
    validarHash();
  }, []);

  useEffect(() => {
    if (!firstCall) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            buscarHash(token);
            return 0;
          }
          const diff = 3;
          return Math.min(oldProgress + diff, 100);
        });
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [firstCall]);

  return (
    <div>
      <QrCode size={200} value={qrCodeValue} />
      <div style={{ width: "100%", zIndex: 999, minHeight: 10 }}>
        <LinearDeterminate progress={progress} />
      </div>
    </div>
  );
};

export default TutorialGuide;
