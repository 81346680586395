import React from "react";

import TutorialGuide from "../TutorialGuide";
//import VideoGuide from "../VideoGuide";

import "./styles.scss";

const MainContent = () => (
  <main className="content">
    <TutorialGuide />
  </main>
);

export default MainContent;
