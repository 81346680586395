import React from "react";

import Header from "./Header";
import MainContent from "./MainContent";

import "./styles.scss";

const Home = () => (
  <div className="top-background">
    <Header />
    <MainContent />
    <p style={{ color: "white", width: "90vw", textAlign: "center" }}>
      Acesse o Medical APP e leia o QRCODE para validar seu checkin/checkout.
    </p>
  </div>
);

export default Home;
